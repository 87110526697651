import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { BackgroundImage } from './components/BackgroundImage';
import { Home } from './components/Home';
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Claims } from './components/Claims';
import { SecurityDepositInsurance } from './components/SecurityDepositInsurance';
import { TenantLegalLiability } from './components/TenantLegalLiability';
import { Footer } from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const MainContent: React.FC = () => {

    // Proceed with Code
  const location = useLocation();
  const showBackground = !['/claims'].includes(location.pathname);

  return (
    <>
      <Navbar />
      {showBackground && <BackgroundImage />}
      <main className={`flex-grow-1 bg-white ${showBackground ? 'mt-2' : ''}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/security-deposit-insurance" element={<SecurityDepositInsurance />} />
          <Route path="/tenant-legal-liability" element={<TenantLegalLiability />} />
          <Route path="/claims" element={<Claims />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};

export const App: React.FC = () => {
  return (
    <div className="App d-flex flex-column min-vh-100">
      <MainContent />
    </div>
  );
};

// <Route path="/about" element={<About />} />
// <Route path="/contact" element={<Contact />} />
// <Route path="/claims" element={<Claims />} />
// <Route path="/security-deposit-insurance" element={<SecurityDepositInsurance />} />
// <Route path="/tenant-legal-liability" element={<TenantLegalLiability />} />

