import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import './About.css';

interface EmployeeProps {
  name: string;
  title: string;
  bio: string;
  image: string;
}

const EmployeeCard: React.FC<EmployeeProps> = ({ name, title, bio, image }) => (
  <Card className="mb-4 employee-card">
    <Row noGutters>
      <Col md={3} className="image-container">
        <div className="image-wrapper">
          <img src={image} alt={name} className="employee-image" />
        </div>
      </Col>
      <Col md={9}>
        <Card.Body>
          <Card.Title><strong>{name}</strong></Card.Title>
          <hr className="employee-divider" />
          <Card.Subtitle className="mb-2 mt-2">{title}</Card.Subtitle>
          <Card.Text>{bio}</Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>
);

export const About: React.FC = () => {
  const employees: EmployeeProps[] = [
    {
      name: "James \"Doug\" Keys",
      title: "Founder & President",
      bio: "Doug Keys is a graduate of Indiana University's Kelley School of Business with a bachelor's degree in finance. He began his career in Chicago with AON Corporation from 1985 to 2002 where he was the number one area manager handling auto dealers' captive reinsurance programs in Illinois and Indiana. In 2002, Doug teamed up with Jim Smith and started a national auto program with 4,300 auto dealers. Always looking at new industries, Doug started Warranty Global Group Inc. for the real estate and mortgage industries offering home warranty policies which later expanded to a consumer direct program. Doug Keys sold this company to Jim Smith in late 2016 to start their new venture with Your Renters Insurance Group. Doug lives in Mokena, IL with his wife of twenty years and sixteen-year-old daughter.",
      image: "/images/DougKeys.png"
    },
    {
      name: "James B. \"Jim\" Smith",
      title: "Chief Executive Officer",
      bio: "Jim Smith is a graduate of Texas Tech University with a degree in accounting. He has spent his entire career in the insurance industry, beginning in accounting roles with a major insurance company and ultimately evolving to become the CEO and Chairman of the Board of one of the largest insurance providers. Jim sold his organization in 2018, which consisted of a major A-Rated insurance company, a nationally recognized third-party administrator (TPA) and ancillary companies developed to complement his organization, including a software development company and a captive management company. Since that time, Jim has entered two new endeavors that allow him to utilize the knowledge and experience he gained through his earlier business enterprises to create reinsurance programs that allow his clients to share in the profits from their insurance programs.",
      image: "/images/JimSmith.jpg"
    },
    {
      name: "Kelly Carroll, CPA",
      title: "Head of Finance",
      bio: "Kelly Carroll is a graduate of Indiana University's Kelley School of Business with a bachelor's degree in accounting and is CPA licensed. She began her career in Chicago in public accounting with Grant Thornton before transitioning to the new joint venture between MacMillan/McGraw-Hill School Publishing Company. Following this, Kelly joined the international division of Abbott Laboratories where she assisted with international taxes and budgeting as well as developing skills with computer and system program development. In 1996, Kelly moved to Milwaukee to raise her two children and focused on volunteering her time to fundraising for nonprofit organizations. In 2004, she joined her brother, Doug Keys, at his start-up company Warranty Global Group where she served as Project Manager and Operations Officer. Today, Kelly is the Head of Finance at Your Renters Insurance Group. Kelly currently lives in Indiana and enjoys spending her time on a lake close to family.",
      image: "/images/KellyCarroll.jpg"
    }
  ];

  return (
    <Container>
      <h2 className="mobile-only-title">About Us</h2>  
      <p className="my-4">
      YRIG RRG is an insurance company designed to protect landlords from the potential liability of your tenants. We provide the coverage necessary to provide you with peace of mind for liability insurance so that you can focus on the important aspects of tenant occupancy and retention. Not only do we offer protection, but also the opportunity to participate in the profits of the insurance premiums that you pay. 
      </p>
      {employees.map((employee, index) => (
        <EmployeeCard key={index} {...employee} />
      ))}
      <hr />
      <p className="small mt-4 font-italic">
        This electronic information is published by YRIG Risk Retention Group, Inc., a risk retention group, for educational purposes only and is not intended to be a solicitation or sale of insurance to any person not eligible for membership or in states where the risk retention group has not filed its registration as required by the federal Liability Risk Retention Act of 1986 (LRRA). Risk retention groups operate under the federal Liability Risk Retention Act of 1986 and provide insurance for the common liability risk exposure of eligible group members. Different state laws may apply.
      </p>
      <p className="small font-italic">
        Policies issues by a risk retention group may not be subject to all of the insurance laws and regulations of your state. State insurance insolvency guaranty funds are not available for risk retention groups. This electronic information is intended solely to provide general information and is not intended to constitute legal advice. If legal advice is desired or needed, an attorney should be consulted.
      </p>
      <Row>
        <Col sm={3}>
            <a 
            href="https://yrigprodstore.blob.core.windows.net/portalcontent/YRIGRRG%20-%20Code%20of%20Business%20Conduct%20and%20Ethics%20(Adopted%2010-29-2020).pdf" 
            target="_blank" 
            rel="noopener noreferrer" 
            >
            Code of Business Conduct
            </a>
        </Col>
        <Col sm={3}>
            <a 
            href="https://yrigprodstore.blob.core.windows.net/portalcontent/YRIGRRG%20-%20Governance%20Standards%20(Adopted%2010-29-2020).pdf" 
            target="_blank" 
            rel="noopener noreferrer"
            >
            Governance Standards
            </a>
      </Col>
      </Row>
    </Container>
  );
};