import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, NavDropdown, Container } from 'react-bootstrap';

export const Navbar: React.FC = () => {
  return (
    <BootstrapNavbar expand="md" className="custom-navbar">
      <Container className="navbar-container">
        <BootstrapNavbar.Brand as={NavLink} to="/" className="custom-brand me-auto">
        <img src="/images/yri-logo-v2.png" alt="YRIG"  className="logoimage" />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <Nav.Link as={NavLink} to="/" className="custom-nav-link">Home</Nav.Link>
            <NavDropdown title="Products" id="basic-nav-dropdown" className="custom-nav-link custom-dropdown">
              <NavDropdown.Item as={NavLink} to="/security-deposit-insurance">Security Deposit Insurance</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/tenant-legal-liability">Tenant Legal Liability</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to="/about" className="custom-nav-link">About</Nav.Link>
            <Nav.Link as={NavLink} to="/contact" className="custom-nav-link">Contact</Nav.Link>
            <Nav.Link as={NavLink} to="/claims" className="custom-nav-link">Claims</Nav.Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};