import React from 'react';
import { Container, Card } from 'react-bootstrap';
// import MaterialIcon, {colorPalette} from 'material-icons-react';
// import ApartmentIcon from '@mui/icons-material/Apartment';

export const TenantLegalLiability: React.FC = () => {
    return (
        <Container>
            <h2 className="mobile-only-title">Tenant Legal Liability</h2>
            <div className="text-center my-4">
                <span className="material-icons">apartment</span>
            </div>
            <Card>
                <Card.Body>
                    <h2>Additional Protection</h2>
                    <p>As part of the coverage by YRIG RRG, we cover the landlord in the event of liabilities that the tenant could incur that a Tenants Legal Liability would cover. If the tenant does experience a claim of this nature and does not have insurance, then the YRIG contractual liability will cover you, the landlord. We provide peace of mind and allow you to participate in your insurance underwriting profits at the same time.</p>
                </Card.Body>
            </Card>
        </Container>
    );
};