import React from 'react';
import { Container, Table } from 'react-bootstrap';

interface MarketingContact {
    name: string;
    email: string;
    phone: string;
}

export const Contact: React.FC = () => {
    const marketingContacts: MarketingContact[] = [
        { name: "Duane Hughes", email: "dhughes@yrig.com", phone: "312-545-8700" },
        { name: "Tom McDonough", email: "jmcdonough@yrig.com", phone: "413-841-6453" },
        { name: "William Shaver (Cub)", email: "cshaver@yrig.com", phone: "708-819-5100" },
        { name: "Darryl Andrews", email: "dandrews@yrig.com", phone: "817-683-1154" },
        { name: "Mike Griffith", email: "mgriffith@yrig.com", phone: "215-370-1492" },
        { name: "Phil Beans", email: "wpbeans@yrig.com", phone: "419-261-8859" },
        { name: "Greg Coots", email: "gcoots@yrig.com", phone: "214-415-4951" }
    ];

    return (
        <Container>
            <h2 className="mobile-only-title">Contact Information</h2>  
            <p/>
            <h2>Registered Office/Agent in State of Domicile</h2>
            <p>641 South Lawrence Street Montgomery AL 36104</p>
            <h3>Administration</h3>
            <p>
                Email: <a href="mailto:admin@yrig.com">admin@yrig.com</a><br />
                Phone: <a href="tel:888-585-9744">888-585-9744 (YRIG)</a>
            </p>
            <h3>Claims</h3>
            <p>
                Email: <a href="mailto:claims@yrig.com">claims@yrig.com</a><br />
                Phone: <a href="tel:888-585-4974">888-585-4974</a>
            </p>
            <h2>Marketing</h2>
            <Table striped bordered hover responsive>
                <thead className="bg-light-blue">
                    <tr>
                        <th>Agent</th>
                        <th>Email</th>
                        <th>Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {marketingContacts.map((contact, index) => (
                        <tr key={index}>
                            <td>{contact.name}</td>
                            <td><a href={`mailto:${contact.email}`}>{contact.email}</a></td>
                            <td><a href={`tel:${contact.phone}`}>{contact.phone}</a></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
    
};
