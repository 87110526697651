import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

interface BenefitProps {
    title: string;
    description: string;
}

const BenefitCard: React.FC<BenefitProps> = ({ title, description }) => (
    <Card className="h-100">
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text color='light-grey'>{description}</Card.Text>
        </Card.Body>
    </Card>
);

const BenefitSection: React.FC<{ title: string; benefits: BenefitProps[] }> = ({ title, benefits }) => (
    <div className="my-4">
        <h2>{title}</h2>
        <Row xs={1} md={2} className="g-4">
            {benefits.map((benefit, idx) => (
                <Col key={idx} className="mb-4">
                    <BenefitCard {...benefit} />
                </Col>
            ))}
        </Row>
    </div>
);

export const Home: React.FC = () => {
    const propertyManagerBenefits = [
        {
            title: "UNIQUE PROFIT-SHARING STRUCTURE",
            description: "In the YRIG program, the landlord is purchasing insurance in order to protect themselves in the event of tenant non-performance. The YRIG program allows the landlord to participate in the premiums they pay for this coverage."
        },
        {
            title: "PEACE OF MIND PROTECTION",
            description: "In the YRIG program, the landlord is purchasing insurance in order to protect themselves in the event of tenant non-performance. The YRIG program allows the landlord to participate in the premiums they pay for this coverage."
        },
        {
            title: "FAST TURNAROUND FOR EXCESS WEAR & TEAR",
            description: "Smooth process that minimizes disruptions to the normal course of operations."
        },
        {
            title: "IMPROVED COLLECTIONS & BAD DEBT",
            description: "Let YRIG do the collections without collection fees. Drastically lower your bad debt and increase the owner’s ROI."
        },
        {
            title: "NO MORE ARGUMENTS ON SECURITY DEPOSITS",
            description: "Deliver a solution that builds trust with tenants and provides peace of mind for all parties."
        },
        {
            title: "NO NEED FOR SECURITY DEPOSIT ACCOUNTING",
            description: "Simplifies the landlord / property managers' administrative and reporting requirements."
        },
        {
            title: "NO TENANT APPLICATION",
            description: "If the Property Manager accepts the renter, so does YRIG."
        }
    ];

    return (
        <Container>
            <h2 className="mobile-only-title">Insurance Plans for Landlords</h2>  
            Let YRIG take the burden of bad debt, damages and tenant liability off your hands and provide coverage in the event of non-performance by the tenant,  and also allow you to participate in the underwriting profits of this business.
            <BenefitSection title="Property Manager Benefits" benefits={propertyManagerBenefits} />
        </Container>
    );
};
