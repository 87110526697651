import React, { useState, useRef, useEffect } from 'react';
import { Container, Nav, Form, Button, Col, Row, Card, Modal } from 'react-bootstrap';
import './Claims.css';

export const Claims: React.FC = () => {

   // NOTE:  Environment variables for the front-end are loaded as part of the build process from 
   // the .env files associated with the npm build or npm run commands
   // For Production, these will be manually created in the environment for security
  // Load the backend API URL
  const apiBaseUrl = process.env.REACT_APP_APIURL_WEB
  if (apiBaseUrl === undefined) {
    throw new Error('REACT_APP_APIURL_WEB is not defined in the environment variables');
  }

  // Load the backend API Port
  const apiPort = process.env.REACT_APP_APIPORT_WEB
  if (apiPort === undefined) {
    throw new Error('REACT_APP_APIPORT_WEB is not defined in the environment variables');
  }

  // Build the full API URL
  const apiUrl = apiBaseUrl + ':' + apiPort + '/api/submit-claim';

  const [currentStep, setCurrentStep] = useState(1);
  
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);

  // Step 1 state
  const [policyType, setPolicyType] = useState('');

  // Step 2 state
  const [dateOfLoss, setDateOfLoss] = useState('');
  const [description, setDescription] = useState('');
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const photoInputRef = useRef<HTMLInputElement>(null);

  // Step 3 state
  const [policyNumber, setPolicyNumber] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  // Step 4 state
  const [claimantName, setClaimantName] = useState('');
  const [claimantPhone, setClaimantPhone] = useState('');
  const [claimantEmail, setClaimantEmail] = useState('');
  const [claimantConfirmEmail, setClaimantConfirmEmail] = useState('');
  const [claimantStreet, setClaimantStreet] = useState('');
  const [claimantCity, setClaimantCity] = useState('');
  const [claimantState, setClaimantState] = useState('');
  const [claimantZip, setClaimantZip] = useState('');

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const steps = policyType === 'Security Deposit' 
    ? ['Policy Type', 'Security Deposit Form'] 
    : ['Policy Type', 'Claim Info', 'Insured Info', 'Claimant (Optional)', 'Review'];


    const states = [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD',
        'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH',
        'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ];

  useEffect(() => {
    validateStep(currentStep);
  }, [currentStep, policyType, dateOfLoss, description, policyNumber, name, phone, email, confirmEmail, street, city, state, zip, claimantName, claimantPhone, claimantEmail, claimantConfirmEmail, claimantStreet, claimantCity, claimantState, claimantZip]);

  const validateStep = (step: number) => {
    let stepErrors: { [key: string]: string } = {};
    switch (step) {
        case 1:
            if (!policyType) stepErrors.policyType = "Policy type is required";
            break;
        case 2:
            if (policyType !== 'Security Deposit') {
                if (!dateOfLoss) stepErrors.dateOfLoss = "Date of loss is required";
                if (!description) stepErrors.description = "Description is required";
            }
            break;
        case 3:
            if (policyType !== 'Security Deposit') {
            if (!dateOfLoss) stepErrors.dateOfLoss = "Date of loss is required";
            if (!description) stepErrors.description = "Description is required";
        }
        break;
        case 4:
            if (!policyNumber) stepErrors.policyNumber = "Policy number is required";
            if (!name) stepErrors.name = "Name is required";
            if (!phone) stepErrors.phone = "Phone is required";
            if (!email) stepErrors.email = "Email is required";
            if (email !== confirmEmail) stepErrors.confirmEmail = "Emails do not match";
            if (!street) stepErrors.street = "Street address is required";
            if (!city) stepErrors.city = "City is required";
            if (!state) stepErrors.state = "State is required";
            if (!zip) stepErrors.zip = "ZIP code is required";
            break;
      case 5:
            // Only validate if any field is filled
            if (claimantName || claimantPhone || claimantEmail || claimantStreet) {
            if (!claimantName) stepErrors.claimantName = "Claimant name is required";
            if (!claimantPhone) stepErrors.claimantPhone = "Claimant phone is required";
            if (!claimantEmail) stepErrors.claimantEmail = "Claimant email is required";
            if (claimantEmail !== claimantConfirmEmail) stepErrors.claimantConfirmEmail = "Claimant emails do not match";
            if (!claimantStreet) stepErrors.claimantStreet = "Claimant street address is required";
            if (!claimantCity) stepErrors.claimantCity = "Claimant city is required";
            if (!claimantState) stepErrors.claimantState = "Claimant state is required";
            if (!claimantZip) stepErrors.claimantZip = "Claimant ZIP code is required";
            }
            break;
        }
        setErrors(stepErrors);
        return Object.keys(stepErrors).length === 0;
    };

  const formatPhoneNumber = (value: string) => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setter(formattedPhoneNumber);
  };

  const formatZipCode = (value: string) => {
    const zipCode = value.replace(/[^\d]/g, '');
    const zipCodeLength = zipCode.length;
    if (zipCodeLength <= 5) return zipCode;
    return `${zipCode.slice(0, 5)}-${zipCode.slice(5, 9)}`;
  };

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const formattedZipCode = formatZipCode(e.target.value);
    setter(formattedZipCode);
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setAttachments([...attachments, ...Array.from(event.target.files)]);
    }
  };

  const handleSelectFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

// const handleCameraCapture = () => {
//   if (fileInputRef.current) {
//     fileInputRef.current.click();
//   }
// };

  const handleTakePhoto = () => {
    if (photoInputRef.current) {
      photoInputRef.current.click();
    }
  };
    
  const handleSubmit = async () => {
    if (validateStep(1) && (policyType === 'Security Deposit' || (validateStep(2) && validateStep(3) && validateStep(4)))) {
      const claimData = {
        policyType,
        dateOfLoss: policyType === 'Security Deposit' ? '' : dateOfLoss,
        description: policyType === 'Security Deposit' ? '' : description,
        specialInstructions: policyType === 'Security Deposit' ? '' : specialInstructions,
        policyNumber,
        name,
        phone,
        email,
        address: `${street}, ${city}, ${state} ${zip}`,
        claimantName,
        claimantPhone,
        claimantEmail,
        claimantAddress: claimantStreet ? `${claimantStreet}, ${claimantCity}, ${claimantState} ${claimantZip}` : 'Not provided'
      };

      const formData = new FormData();
      formData.append('claimData', JSON.stringify(claimData));
      
      attachments.forEach((file, index) => {
        formData.append('attachments', file);
      });

      try {
        console.log("Submitting claim...");
        const response = await fetch('/api/submit-claim', { // '/api/submit-claim' apiUrl
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          console.log("Claim submitted successfully");
          setIsSubmitted(true);
          setIsModalClosed(false);
          setShowModal(true);
        } else {
          console.error("Failed to submit claim");
          setShowModal(true);
        }
      } catch (error) {
        console.error("Error submitting claim:", error);
        setShowModal(true);
      }
    } else {
      console.log("Please correct the errors before submitting");
    }
  };

const handleModalClose = () => {
    setShowModal(false);
    if (isSubmitted) {
      setIsModalClosed(true);
    }
  };

const handleNewClaim = () => {
    // Reset all state variables to their initial values
    setDateOfLoss('');
    setDescription('');
    setSpecialInstructions('');
    setAttachments([]);
    setPolicyNumber('');
    setPolicyType('');
    setName('');
    setPhone('');
    setEmail('');
    setConfirmEmail('');
    setStreet('');
    setCity('');
    setState('');
    setZip('');
    setClaimantName('');
    setClaimantPhone('');
    setClaimantEmail('');
    setClaimantConfirmEmail('');
    setClaimantStreet('');
    setClaimantCity('');
    setClaimantState('');
    setClaimantZip('');
    setErrors({});
    setCurrentStep(1);
    setIsSubmitted(false);

    setIsSubmitted(false);
    setIsModalClosed(false);
    setCurrentStep(1);
  };

  const renderField = (label: string, value: string, error?: string) => (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm={3} className='inputPrompt'>{label} {error && <span className="text-danger">*</span>}</Form.Label>
      <Col sm={5}>
      {value.length - (value.replace(/\n/g,'').length) === value.length ? 
            <Form.Control
            type="text"
            value={value}
            readOnly
            
            isInvalid={!!error}
            />         
         : 
            <Form.Control
            as="textarea"
            rows={value.length - (value.replace(/\n/g,'')).length + 1}
            value={value}
            readOnly
            isInvalid={!!error}
        />
      }
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );

  const renderReviewSection = (title: string, data: { [key: string]: string }) => (
    <Card className="mb-3">
      <Card.Header as="h5">{title}</Card.Header>
      <Card.Body>
        {Object.entries(data).map(([key, value]) => renderField(key, value || 'Not provided', errors[key]))}
      </Card.Body>
    </Card>
  );

const renderStepContent = () => {
    switch (currentStep) {
        case 1:
            return (
              <Form>
                <h3>Policy Type</h3>
                <Form.Group as={Row}  className="mb-3">
                  <Form.Label column sm={4} className='inputPrompt'>Which Policy Type are you creating a Claim for?:</Form.Label>
                  <Col sm={3}>
                  <Form.Select
                    value={policyType}
                    onChange={(e) => setPolicyType(e.target.value)}
                    isInvalid={!!errors.policyType}
                  >
                    <option value="">Select Policy Type</option>
                    <option value="Security Deposit">Security Deposit</option>
                    <option value="Tenant Legal Liability">Tenant Legal Liability</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.policyType}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Form>
            );        
        case 2:
            if (policyType === 'Security Deposit') {
                return (
                <iframe
                    title="Security Deposit Claim"
                    src="https://app.smartsheet.com/b/form/71604dc2f64a402ca457e2a4795db3a7"
                    width="100%"
                    height="500px"
                    frameBorder="0"
                ></iframe>
                );
            } else {
                    return (
                        <Form>
                        <h3>Claim Details - {policyType}</h3>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3} className='inputPrompt'>Date of Loss: {errors.dateOfLoss && <span className="text-danger">*</span>}</Form.Label>
                            <Col sm={2}>
                            <Form.Control
                                type="date"
                                value={dateOfLoss}
                                onChange={(e) => setDateOfLoss(e.target.value)}
                                isInvalid={!!errors.dateOfLoss}
                            />
                            <Form.Control.Feedback type="invalid">{errors.dateOfLoss}</Form.Control.Feedback>
                            </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 inputPrompt">
                            <Form.Label column sm={3} className='inputPrompt'>Brief Description of Loss: {errors.description && <span className="text-danger">*</span>}</Form.Label>
                            <Col sm={5}>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                            </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 inputPrompt">
                            <Form.Label column sm={3} className='inputPrompt'>Special Instructions:</Form.Label>
                            <Col sm={5}>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                value={specialInstructions}
                                onChange={(e) => setSpecialInstructions(e.target.value)}
                            />
                            </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3} className='inputPrompt'>Attachments:<br></br>(Optional)</Form.Label>
                            <Col sm={9}>
                            <div className='inputLeft'>
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                accept="image/*,application/pdf"
                                />
                                <input
                                type="file"
                                accept="image/*"
                                capture="environment"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                ref={photoInputRef}
                                />
                                <Button variant="outline-primary" onClick={handleSelectFiles}>
                                Select Files
                                </Button>
                                {' '}
                                <Button variant="outline-secondary" onClick={handleTakePhoto}>
                                Take Photo
                                </Button>
                            </div>
                            </Col>
                            <ul className="mt-2">
                                {attachments.map((file, index) => (
                                <li key={index}>{file.name}</li>
                                ))}
                            </ul>
                            </Form.Group>
                        </Form>
                    );
            }
        case 3:
            return (
            <Form>
            <h3>Insured Information - {policyType}</h3>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className='inputPrompt'>Policy Number: {errors.policyNumber && <span className="text-danger">*</span>}</Form.Label>
                <Col sm={5}>
                <Form.Control
                    type="text"
                    value={policyNumber}
                    onChange={(e) => setPolicyNumber(e.target.value)}
                    isInvalid={!!errors.policyNumber}
                />
                <Form.Control.Feedback type="invalid">{errors.policyNumber}</Form.Control.Feedback>
                </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className='inputPrompt'>Name: {errors.name && <span className="text-danger">*</span>}</Form.Label>
                <Col sm={5}>
                <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className='inputPrompt'>Phone: {errors.phone && <span className="text-danger">*</span>}</Form.Label>
                <Col sm={5}>
                <Form.Control
                    type="tel"
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handlePhoneChange(e, setPhone)}
                    isInvalid={!!errors.phone}
                    placeholder="(___) ___-____"
                    />
                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className='inputPrompt'>Email: {errors.email && <span className="text-danger">*</span>}</Form.Label>
                <Col sm={5}>
                <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className='inputPrompt'>Confirm Email: {errors.confirmEmail && <span className="text-danger">*</span>}</Form.Label>
                <Col sm={5}>
                <Form.Control
                    type="email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    isInvalid={!!errors.confirmEmail}
                />
                <Form.Control.Feedback type="invalid">{errors.confirmEmail}</Form.Control.Feedback>
                </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className='inputPrompt'>Street Address: {errors.street && <span className="text-danger">*</span>}</Form.Label>
                <Col sm={5}>
                <Form.Control
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    isInvalid={!!errors.street}
                />
                <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                </Col>
                </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3} className='inputPrompt'>City, State, Zip: {(errors.city || errors.state || errors.zip) && <span className="text-danger">*</span>}</Form.Label>
                    <Col md={4}>
                            <Form.Control
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                isInvalid={!!errors.city}
                                className='inputleft'
                            />
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>    
                        </Col>
                        <Col md={2}>
                            <Form.Select
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                isInvalid={!!errors.state}
                            >
                                <option value="">Select State</option>
                                {states.map((s) => (
                                <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                        </Col>
                        <Col md={2}>
                            <Form.Control
                                type="text"
                                value={zip}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleZipChange(e, setZip)}
                                isInvalid={!!errors.zip}
                                placeholder="_____-____"
                                />
                            <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>

            </Form>
            );
    case 4:
        return (
        <Form>
            <h3>Claimant Information (Optional) - {policyType}</h3>
            <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3} className='inputPrompt'>Name {errors.claimantName && <span className="text-danger">*</span>}</Form.Label>
            <Col sm={5}>
            <Form.Control
                type="text"
                value={claimantName}
                onChange={(e) => setClaimantName(e.target.value)}
                isInvalid={!!errors.claimantName}
            />
            <Form.Control.Feedback type="invalid">{errors.claimantName}</Form.Control.Feedback>
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3} className='inputPrompt'>Phone {errors.claimantPhone && <span className="text-danger">*</span>}</Form.Label>
            <Col sm={5}>
            <Form.Control
                type="tel"
                value={claimantPhone}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handlePhoneChange(e, setClaimantPhone)}
                isInvalid={!!errors.claimantPhone}
                placeholder="(___) ___-____"
                />
            <Form.Control.Feedback type="invalid">{errors.claimantPhone}</Form.Control.Feedback>
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3} className='inputPrompt'>Email {errors.claimantEmail && <span className="text-danger">*</span>}</Form.Label>
            <Col sm={5}>
            <Form.Control
                type="email"
                value={claimantEmail}
                onChange={(e) => setClaimantEmail(e.target.value)}
                isInvalid={!!errors.claimantEmail}
            />
            <Form.Control.Feedback type="invalid">{errors.claimantEmail}</Form.Control.Feedback>
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3} className='inputPrompt'>Confirm Email {errors.claimantConfirmEmail && <span className="text-danger">*</span>}</Form.Label>
            <Col sm={5}>
            <Form.Control
                type="email"
                value={claimantConfirmEmail}
                onChange={(e) => setClaimantConfirmEmail(e.target.value)}
                isInvalid={!!errors.claimantConfirmEmail}
            />
            <Form.Control.Feedback type="invalid">{errors.claimantConfirmEmail}</Form.Control.Feedback>
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3} className='inputPrompt'>Street Address {errors.claimantStreet && <span className="text-danger">*</span>}</Form.Label>
            <Col sm={5}>
            <Form.Control
                type="text"
                value={claimantStreet}
                onChange={(e) => setClaimantStreet(e.target.value)}
                isInvalid={!!errors.claimantStreet}
            />
            <Form.Control.Feedback type="invalid">{errors.claimantStreet}</Form.Control.Feedback>
            </Col>
            </Form.Group>
                <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className='inputPrompt'>City, State, Zip {(errors.claimantCity || errors.claimantState || errors.claimantZip) && <span className="text-danger">*</span>}</Form.Label>
                <Col sm={4}>
                <Form.Control
                    type="text"
                    value={claimantCity}
                    onChange={(e) => setClaimantCity(e.target.value)}
                    isInvalid={!!errors.claimantCity}
                />
                <Form.Control.Feedback type="invalid">{errors.claimantCity}</Form.Control.Feedback>
                </Col>
                <Col sm={2}>
                <Form.Select
                    value={claimantState}
                    onChange={(e) => setClaimantState(e.target.value)}
                    isInvalid={!!errors.claimantState}
                >
                    <option value="">Select State</option>
                    {states.map((s) => (
                    <option key={s} value={s}>{s}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.claimantState}</Form.Control.Feedback>
                </Col>
                <Col sm={2}>
                <Form.Control
                    type="text"
                    value={claimantZip}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleZipChange(e, setClaimantZip)}
                    isInvalid={!!errors.claimantZip}
                    placeholder="_____-____"
                    />
                <Form.Control.Feedback type="invalid">{errors.claimantZip}</Form.Control.Feedback>
                </Col>
                </Form.Group>
        </Form>
        );
    case 5:
        return (
        <div>
            <h3>Review Your Claim - {policyType}</h3>
            {renderReviewSection("Step 1: Claim Information", {
            "Date of Loss:": dateOfLoss,
            "Description of Loss:": description,
            "Special Instructions:": specialInstructions,
            "Attachments:": attachments.length > 0 ? attachments.map(file => file.name).join(', ') : 'No attachments'
            })}
            {renderReviewSection("Step 2: Insured Information", {
            "Policy Number:": policyNumber,
            "Policy Type:": policyType,
            "Name:": name,
            "Phone:": phone,
            "Email:": email,
            "Address:": `${street}, ${city} ${state} ${zip}`
            })}
            {renderReviewSection("Step 3: Claimant Information (Optional)", {
            "Name:": claimantName,
            "Phone:": claimantPhone,
            "Email:": claimantEmail,
            "Address:": claimantStreet ? `${claimantStreet}, ${claimantCity} ${claimantState} ${claimantZip}` : ''
            })}
        </div>
        );
    default:
    return null;
}
};

return (
    <Container>
      <h1 className="my-4">File a Claim</h1>
      <Nav variant="tabs" className="mb-3">
        {steps.map((step, index) => (
          <Nav.Item key={index}>
            <Nav.Link
              active={currentStep === index + 1}
              onClick={() => setCurrentStep(index + 1)}
            >
              Step {index + 1}: {step}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      {renderStepContent()}
      <div className="d-flex justify-content-between mt-4">
          <Button variant="secondary" 
            onClick={handleBack} 
            className={currentStep > 1 && !isSubmitted && !isModalClosed ? "buttonShow ms-auto" : "buttonHidden ms-auto"}
          >
            Back
          </Button>
          <Button 
            variant="primary" 
            onClick={handleNext}
            className={currentStep < steps.length && !isSubmitted && !isModalClosed ? "buttonShow ms-auto" : "buttonHidden ms-auto"}
          >
            Next
          </Button>
          <Button 
            variant="success" 
            onClick={handleSubmit}
            className={currentStep === steps.length && !isSubmitted && !isModalClosed && !(policyType === 'Security Deposit') ? "buttonShow ms-auto" : "buttonHidden ms-auto"}
          >
            Submit Claim
          </Button>
          <Button 
            variant="primary" 
            onClick={handleNewClaim}
            className={(isSubmitted && isModalClosed) || (policyType === 'Security Deposit' && currentStep !== 1) ? "buttonShow ms-auto":"buttonHidden ms-auto"}
            >
            Start New Claim
          </Button>
        </div>
        <br/>
        <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>{isSubmitted ? "Claim Submitted" : "Submission Error"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isSubmitted 
                ? "Your claim has been submitted successfully." 
                : "There was an error submitting your claim. Please try again."}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                Close
                </Button>
            </Modal.Footer>
        </Modal>
    </Container>
  );
};

export default Claims;