// components/Footer.tsx
import React from 'react';
import { Container } from 'react-bootstrap';

export const Footer: React.FC = () => {
  return (
    <footer className="footer mt-auto py-3">
      <Container>
        <span>&copy; 2024 YRIG Risk Retention Group, Inc. All rights reserved.</span>
        <br />
        <span className="py-4">Nothing on this website is intended to act as a solicitation or offer for the purchase or sale of insurance in the state of Texas.</span>
      </Container>
    </footer>
  );
};
